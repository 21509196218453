@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500;
  }

  .btn-disabled {
    @apply inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-300;
  }

  .btn-secondary {
    @apply inline-flex items-center justify-center border-none shadow-sm font-medium rounded-md text-indigo-500 bg-indigo-100 hover:bg-indigo-200 focus:ring-offset-white focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .btn-secondary-disabled {
    @apply inline-flex items-center justify-center border-none shadow-sm font-medium rounded-md text-indigo-100 bg-gray-100 opacity-50 cursor-not-allowed;
  }

  .btn-secondary-xs {
    @apply px-2.5 py-1.5 rounded-sm text-xs leading-4 font-medium;
  }

  .btn-secondary-sm {
    @apply px-3.5 py-2.5 rounded-sm text-sm leading-4 font-medium;
  }

  .btn-secondary-base {
    @apply px-4 py-2 rounded text-sm leading-5 font-medium;
  }

  .btn-secondary-l {
    @apply px-4 py-2 rounded-l text-base leading-6 font-medium;
  }

  .btn-secondary-xl {
    @apply px-6 py-3 rounded-xl text-base leading-6 font-medium;
  }

  .checkbox {
    @apply h-4 w-4 border border-gray-300 rounded bg-white focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain float-left cursor-pointer;
  }

  nav a.active {
    @apply bg-gray-100;
  }

  .comfortable-table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    @apply border-gray-200 shadow-md;
  }

  .comfortable-table td,
  .comfortable-table th {
    @apply border-gray-200;
  }

  .comfortable-table td {
    @apply text-gray-900 text-sm py-4 px-6;
  }

  .comfortable-table th {
    border-bottom-width: 1px;
    @apply text-gray-500 text-xs leading-4 font-medium tracking-wider uppercase text-left px-6;
  }

  .comfortable-table th:first-of-type {
    border-top-left-radius: 8px;
  }

  .comfortable-table th:last-of-type {
    border-top-right-radius: 8px;
  }

  .comfortable-table tr td {
    border-bottom-width: 1px;
  }

  .comfortable-table tr:last-of-type td {
    border-bottom-width: 0;
  }

  .comfortable-table tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 8px;
  }

  .comfortable-table tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 8px;
  }

  .compact-table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 0;
    border-style: solid;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  .compact-table td,
  .compact-table th {
    @apply border-gray-200;
  }

  .compact-table th {
    @apply text-gray-500 text-sm font-medium tracking-wider uppercase text-left px-1 py-1;
  }

  .compact-table td {
    @apply text-gray-900 text-sm font-medium py-2 px-1;
  }

  .compact-table th {
    border-bottom-width: 1px;
  }

  .compact-table tr td {
    border-bottom-width: 1px;
  }

  .btn-round {
    @apply rounded-full;
  }

  .btn-round-primary {
    @apply bg-blue-600 text-white inline-flex items-center justify-center p-1 border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500;
  }

  .btn-round-xs {
    @apply text-xs;
    width: 1.5rem;
    height: 1.5rem;
  }

  .btn-round-sm {
    @apply text-xs;
    width: 1.875rem;
    height: 1.875rem;
  }

  .btn-round-base {
    @apply text-xs;
    width: 2.125rem;
    height: 2.125rem;
  }

  .btn-round-md {
    @apply text-xs;
    width: 2.375rem;
    height: 2.375rem;
  }

  .btn-round-l {
    @apply text-xs;
    width: 2.625rem;
    height: 2.625rem;
  }

  .btn-round-xl {
    @apply text-xs;
    width: 3.125rem;
    height: 3.125rem;
  }
}
